export function carousel(slideCount) {
  return {
    currentIndex: 0,
    moveInterval: null,
    started: false,
    paused: false,
    stopped: true,
    start() {
      this.started = true;
    },
    reset() {
      this.started = false;
      this.paused = false;
      this.stopped = false;
      this.currentIndex = 0;
    },
    pause() {
      this.paused = true;
    },
    resume() {
      this.paused = false;
    },
    stop() {
      this.started = false;
      this.paused = false;
      this.stopped = true;
    },
    next() {
      this.goTo(this.currentIndex >= (slideCount-1) ? 0 : this.currentIndex + 1);
    },
    prev() {
      this.goTo(this.currentIndex <= 0 ? (slideCount-1) : this.currentIndex - 1);
    },
    goTo(index) {
      this.currentIndex = index;
      this.to((current, offset) => {
        return offset * index;
      });
    },
    to(strategy) {
      let slider = this.$refs.slider;
      let slides = this.$refs.slides;
      let current = slider.scrollLeft
      let offset = slides.firstElementChild.getBoundingClientRect().width
      let left = window.screen.width >= 1024 ? 0 : strategy(current, offset);
      slider.scrollTo({ left: left, behavior: 'instant' });
    },
    focusableWhenVisible: {
      'x-intersect:enter'() {
        this.$el.removeAttribute('tabindex')
      },
      'x-intersect:leave'() {
        this.$el.setAttribute('tabindex', '-1')
      },
    },
    intersections: {
      'x-intersect:enter.margin.-150px'() {
        if(window.screen.width < 1024) {
          let slideEls = this.$el.parentElement.getElementsByClassName('slide');
          this.currentIndex = Array.from(slideEls).indexOf(this.$el);
        }
      },
    }
  };
}

export function mobileCarousel(slideCount) {
  return {
    skip: 1,
    atBeginning: false,
    atEnd: false,
    currentIndex: 0,
    slidesVisible: [],
    allVisible: null,
    moveInterval: null,
    transitionTimeout: null,
    exitingIndex: null,
    started: false,
    start() {
      this.started = true;
      this.resetInterval();
    },
    resetInterval() {
      console.log('reset interval');
      clearInterval(this.moveInterval);
      this.moveInterval = setInterval(() => {
        this.next();
      }, 8000);
    },
    next() {
      this.resetInterval();
      this.to((current, offset) => {
        return current + (offset * this.skip)
      });
    },
    prev() {
      this.resetInterval();
      this.to((current, offset) => {
        return current - (offset * this.skip)
      });
    },
    goTo(index, exiting) {
      if(!exiting) {
        this.exitingIndex = this.currentIndex;
      }
      this.currentIndex = index;
      this.to((current, offset) => {
        //console.log(index, current, (offset * index));
        return offset * index;
      });
      this.resetInterval();
    },
    to(strategy) {
      let slider = this.$refs.slider
      let current = slider.scrollLeft
      let offset = slider.firstElementChild.getBoundingClientRect().width
      let behavior = window.screen.width >= 1024 ? 'instant' : 'smooth';
      slider.scrollTo({left: strategy(current, offset), behavior: behavior});
      setTimeout(() => {
        this.exitingIndex = null;
      }, 300);
    },
    focusableWhenVisible: {
      'x-intersect:enter'() {
        this.$el.removeAttribute('tabindex')
      },
      'x-intersect:leave'() {
        this.$el.setAttribute('tabindex', '-1')
      },
    },
    disableNextAndPreviousButtons: {
      'x-intersect:enter.margin.-150px'() {
        let slideEls = this.$el.parentElement.getElementsByClassName('slide');

        // If this is the first slide.
        if (slideEls[0] === this.$el) {
          this.atBeginning = true
          // If this is the last slide.
        } else if (slideEls[slideEls.length - 1] === this.$el) {
          this.atEnd = true
        }

        this.slidesVisible.push(this.$el);
        this.allVisible = this.slidesVisble == slideEls.length;
        this.currentIndex = Array.from(slideEls).indexOf(this.$el);
      },
      'x-intersect:leave.margin.-100px'() {
        let slideEls = this.$el.parentElement.getElementsByClassName('slide');

        // If this is the first slide.
        if (slideEls[0] === this.$el) {
          this.atBeginning = false
          // If this is the last slide.
        } else if (slideEls[slideEls.length - 1] === this.$el) {
          this.atEnd = false
        }

        this.slidesVisible.pop(this.$el);
        this.allVisible = false;
      },
    },
  };
}
